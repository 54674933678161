<template>
  <section class="n-section-primary">
    <h3 class="tw-text-center">{{ vars.titleText }}</h3>
    <div
      class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-gap-10 tw-px-8 tw-py-14"
    >
      <Swiper
        :modules="[SwiperPagination]"
        :pagination="true"
        :slides-per-view="1"
        :space-between="50"
        effect="fade"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 100,
          },
        }"
        @swiper="onSwiper"
        class="tw-w-full tw-max-w-lg md:tw-max-w-full"
      >
        <SwiperSlide v-for="(reason, index) in reasons" :key="index">
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-pb-10 tw-text-center"
          >
            <NuxtImg :src="reason.photo" class="tw-mb-6 tw-w-36" />

            <div>
              <h4>{{ reason.title }}</h4>
              <p class="tw-pt-4">{{ reason.description }}</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TakeAction',

  mixins: [ComponentMixin],

  computed: {
    reasons() {
      return this.groupedVariables.reasons;
    },
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },
  },
});
</script>
