<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});`"
  >
    <div
      v-if="variants.GoogleReviews === variant"
      class="tw-w-full tw-text-center"
    >
      <h3 class="tw-pt-14">Google Reviews</h3>
      <div
        class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-gap-10 tw-py-14"
      >
        <Swiper
          :modules="[SwiperPagination]"
          :pagination="true"
          :slides-per-view="1"
          :space-between="30"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1336: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }"
          class="tw-w-full tw-max-w-lg lg:tw-max-w-full"
        >
          <SwiperSlide v-for="(review, index) in reviews" :key="index">
            <div
              class="tw-flex tw-h-full tw-min-h-[400px] tw-flex-col tw-gap-3 tw-rounded-lg tw-border-2 tw-p-10 tw-text-start"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <svg
                  v-for="n in review.rating"
                  :key="n"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                    fill="#aead66"
                  />
                </svg>
              </div>
              <h6>{{ review.title }}</h6>
              <span class="tw-font-bold">{{ review.date }}</span>
              <p class="tw-line-clamp-[8] tw-pt-4">{{ review.content }}</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div
      v-if="variants.GoogleReviewsV2 === variant"
      class="tw-flex tw-w-full tw-flex-col tw-items-center tw-text-center"
    >
      <h2 class="tw-pt-14">Google Reviews</h2>
      <div
        class="tw-relative tw-flex tw-h-full tw-w-full tw-max-w-lg tw-flex-col tw-items-center tw-gap-10 tw-py-14 lg:tw-max-w-full"
      >
        <Swiper
          :modules="[SwiperPagination]"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
          :slides-per-view="1"
          :space-between="30"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1336: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }"
          class="tw-w-full"
        >
          <SwiperSlide v-for="(review, index) in reviews" :key="index">
            <div
              class="n-testimonial-card tw-flex tw-h-full tw-min-h-[400px] tw-flex-col tw-gap-3 tw-p-10 tw-text-start"
              style="border-radius: var(--rounded)"
            >
              <div class="tw-flex tw-justify-between">
                <span class="tw-font-bold">{{ review.date }}</span>
                <div class="tw-flex tw-items-center tw-gap-2">
                  <svg
                    v-for="n in review.rating"
                    :key="n"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                      fill="var(--c-secondary)"
                    />
                  </svg>
                </div>
              </div>
              <h4>{{ review.title }}</h4>
              <p class="tw-line-clamp-[8] tw-pt-4">{{ review.content }}</p>
            </div>
          </SwiperSlide>
        </Swiper>
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v3"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'IntroSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        GoogleReviews: 'GoogleReviews',
        GoogleReviewsV2: 'GoogleReviewsV2',
      },
      reviews: [
        {
          rating: 5,
          title: 'First Class Experience',
          date: 'Apr 20, 2024',
          content:
            'Lorem ipsum dolor sit amet consectetur. Nunc nunc mi nibh egestas diam aliquet. Maecenas volutpat ac adipiscing sed leo tortor semper interdum. Pellentesque aliquam aliquam congue risus mattis. Lorem ipsum dolor sit amet consectetur. Nunc nunc mi nibh egestas diam aliquet. Maecenas volutpat ac adipiscing sed leo tortor semper interdum. Pellentesque aliquam aliquam congue risus mattis.  Maecenas volutpat ac adipiscing sed leo tortor semper interdum. Pellentesque aliquam aliquam congue risus mattis. Lorem ipsum dolor sit amet consectetur. Nunc nunc mi nibh egestas diam aliquet. Maecenas volutpat ac adipiscing sed leo tortor semper interdum. Pellentesque aliquam aliquam congue risus mattis.',
        },
        {
          rating: 5,
          title:
            'First Class Experience First class experience first class experiencee',
          date: 'Apr 20, 2024',
          content:
            'Lorem ipsum dolor sit amet consectetur. Nunc nunc mi nibh egestas diam aliquet.',
        },
        {
          rating: 5,
          title: 'First Class Experience',
          date: 'Apr 20, 2024',
          content:
            'Lorem ipsum dolor sit amet consectetur. Nunc nunc mi nibh egestas diam aliquet. Maecenas volutpat ac adipiscing sed leo tortor semper interdum. Pellentesque aliquam aliquam congue risus mattis. Lorem ipsum dolor sit amet consectetur. Nunc nunc mi nibh egestas diam aliquet. Maecenas volutpat ac adipiscing sed leo tortor semper interdum. Pellentesque aliquam aliquam congue risus mattis.',
        },
        {
          rating: 5,
          title: 'First Class Experience',
          date: 'Apr 20, 2024',
          content:
            'Lorem ipsum dolor sit amet consectetur. Nunc nunc mi nibh egestas diam aliquet. Maecenas volutpat ac adipiscing sed leo tortor semper interdum. Pellentesque aliquam aliquam congue risus mattis. Lorem ipsum dolor sit amet consectetur. Nunc nunc mi nibh egestas diam aliquet. Maecenas volutpat ac adipiscing sed leo tortor semper interdum. Pellentesque aliquam aliquam congue risus mattis.',
        },
      ],
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },
  },
});
</script>
