<template>
  <lazy-theme4-base-not-found
    class="tw-flex tw-items-center tw-justify-center tw-text-center"
    :is-full-screen-error="true"
    :background-color="vars.backgroundColor"
  >
    <template #title>
      <h3 class="tw-pb-8">{{ vars.titleText }}</h3>
      <nuxt-link :class="`n-${vars.buttonTypeText}`" :to="vars.buttonLinkUrl">
        {{ vars.buttonLabelText }}
      </nuxt-link>
    </template>
  </lazy-theme4-base-not-found>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'NotFoundPage',

  mixins: [ComponentMixin],

  setup() {
    const event = useRequestEvent();

    // event will be undefined in the browser
    if (event) {
      // Set the status message as well
      setResponseStatus(event, 404, 'Page Not Found');
    }
  },
});
</script>
