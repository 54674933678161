<template>
  <section class="n-section-primary">
    <h3 class="tw-mb-8">{{ vars.titleText }}</h3>

    <!-- Mobile Slider -->

    <Swiper
      :modules="[SwiperPagination]"
      :pagination="true"
      :slides-per-view="1"
      :space-between="30"
      effect="fade"
      :clickable="true"
      :breakpoints="{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      }"
      class="tw-w-full lg:!tw-hidden"
    >
      <SwiperSlide
        v-for="(item, index) in cards"
        :key="`location-${index}`"
        class="!tw-flex !tw-h-auto tw-max-w-none tw-items-center tw-justify-center"
      >
        <div class="tw-relative">
          <NuxtImg
            :src="item.photo"
            class="tw-inset-0 tw-h-full tw-min-h-[320px] tw-w-full"
          />
          <div
            class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-center tw-rounded-lg tw-bg-black tw-bg-opacity-20 tw-text-white"
          >
            <h4 class="tw-pb-4">{{ item.title }}</h4>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

    <!-- Dekstop Grid View -->

    <div
      class="tw-hidden tw-grid-cols-3 tw-grid-rows-2 tw-gap-6 lg:tw-grid xl:tw-gap-10"
    >
      <nuxt-link
        v-for="(item, index) in cards"
        :to="item.link"
        :external="true"
        :key="`location-${index}`"
        class="n-link tw-group tw-relative tw-max-h-96 tw-overflow-hidden tw-rounded-lg tw-duration-300 hover:tw-scale-95"
        :class="{ 'tw-col-span-2': item.wide }"
      >
        <NuxtImg
          :src="item.photo"
          class="tw-inset-0 tw-z-0 tw-h-full tw-w-full tw-duration-300 group-hover:tw-rotate-2 group-hover:tw-scale-105"
        />
        <div
          class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-center tw-rounded-lg tw-bg-black tw-bg-opacity-20 tw-text-white"
        >
          <h4 class="tw-pb-4">{{ item.title }}</h4>
        </div>
      </nuxt-link>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurLocations',

  mixins: [ComponentMixin],

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>
