<template>
  <section
    class="tw-items-center tw-justify-center tw-py-24"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <h2
      class="tw-text-3xl tw-font-bold lg:tw-text-4xl"
      style="line-height: 180%"
    >
      {{ vars.titleText }}
    </h2>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TextBanner',

  mixins: [ComponentMixin],
});
</script>
