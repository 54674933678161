<template>
  <div
    class="guide-card tw-relative tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-flex-col tw-justify-start tw-gap-0 tw-rounded-lg tw-bg-white tw-text-start tw-text-black"
    @click="$emit('open-guide-popup')"
  >
    <div class="tw-p-4">
      <LibraryImage
        :src="guide.photo"
        :h-ratio="1.5"
        :w-ratio="1"
        class="tw-overflow-hidden tw-rounded-[var(--rounded-sm)]"
      />
    </div>
    <div
      class="tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-start tw-gap-4 tw-px-6 tw-pb-6 tw-text-start"
    >
      <div>
        <h5 class="tw-line-clamp-3">{{ guide.title }}</h5>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'Theme4GuideCard',

  props: {
    guide: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },
});
</script>

<style scoped>
.guide-card {
  transition: all 0.3s;
}

.guide-card:hover {
  box-shadow: var(--c-box_shadow);
  transform: scale(0.98);
}
</style>
