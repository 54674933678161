<template>
  <section class="tw-h-screen lg:tw-h-[70vh]">
    <NuxtImg
      :src="vars.backgroundImage"
      class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-object-center"
    />
    <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50"></div>
    <div class="tw-relative tw-max-w-2xl tw-px-2 tw-py-20 tw-text-center">
      <h3 class="tw-mb-8 tw-pt-3 tw-text-white">
        {{ vars.titleText }}
      </h3>
      <p
        v-for="(item, index) in descriptions"
        :key="`take-action-description-${index}`"
        class="tw-mb-8 tw-text-white"
      >
        {{ item.description }}
      </p>
      <nuxt-link
        :class="`n-${vars.buttonTypeText}`"
        :to="vars.buttonLinkUrl"
        :external="true"
      >
        {{ vars.buttonLabelText }}
      </nuxt-link>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TakeAction',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
