<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color:var(--c-text-primary)`"
  >
    <div v-if="variants.v1 === variant" class="tw-w-full tw-text-center">
      <h3 class="tw-mb-8">{{ vars.titleText }}</h3>

      <div
        class="tw-flex tw-flex-col tw-flex-wrap tw-justify-center tw-gap-10 lg:tw-flex-row"
      >
        <div
          class="tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-start tw-rounded-lg tw-border-2 tw-p-4 tw-text-center"
          v-for="(card, index) in cards"
          :key="`valuation-card-${index}`"
        >
          <library-image
            :src="card.photo"
            style="border-radius: var(--rounded-sm)"
          />

          <div
            class="tw-mt-2 tw-flex tw-flex-col tw-items-center tw-justify-stretch tw-gap-3 tw-px-4 tw-py-2"
          >
            <h4>{{ card.title }}</h4>
            <p class="tw-mb-4 tw-line-clamp-[6]">
              {{ card.description }}
            </p>
          </div>
          <button
            class="tw-mb-4 tw-mt-auto"
            :class="`n-${card.button_type}`"
            :to="card.button_link"
          >
            {{ card.button_label }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="variants.v2 === variant"
      class="tw-flex tw-w-full tw-justify-center tw-text-center"
    >
      <div class="tw-relative tw-w-full tw-max-w-lg lg:tw-max-w-full">
        <h2 class="tw-mb-6 md:tw-mb-10">{{ vars.titleText }}</h2>
        <Swiper
          :modules="[SwiperPagination]"
          :slides-per-view="1"
          :space-between="10"
          effect="fade"
          :clickable="true"
          :watch-overflow="true"
          @slideChange="onSlideChange"
          :breakpoints="{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }"
          @swiper="onSwiper"
          class="tw-w-full lg:tw-max-w-full"
        >
          <SwiperSlide
            v-for="(card, index) in cards"
            :key="`valuation-card-${index}`"
            class="tw-flex tw-flex-col tw-items-center tw-justify-start tw-rounded-lg tw-bg-white tw-p-4 tw-text-center"
          >
            <library-image
              :src="card.photo"
              style="border-radius: var(--rounded-sm)"
            />

            <div
              class="tw-mt-2 tw-flex tw-flex-col tw-items-center tw-justify-stretch tw-gap-3 tw-px-4 tw-py-2"
            >
              <h4>{{ card.title }}</h4>
              <p class="tw-mb-4 tw-line-clamp-[6]">
                {{ card.description }}
              </p>
            </div>
            <button
              class="tw-mb-4 tw-mt-auto"
              :class="`n-${card.button_type}`"
              :to="card.button_link"
            >
              {{ card.button_label }}
            </button>
          </SwiperSlide>
        </Swiper>
        <div class="xl:!tw-hidden">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v3"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurValuations',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>
