<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-grid tw-max-w-xl tw-gap-3 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-items-center xl:tw-gap-x-24"
    >
      <NuxtImg
        v-if="vars.photoImage && !vars.videoUrl"
        :src="vars.photoImage"
        :alt="vars.titleText"
        class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
        :class="{
          'xl:tw-col-start-8 xl:tw-row-start-1': vars.isReversedBool,
        }"
      />
      <div
        v-if="vars.videoUrl"
        class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
        :class="{
          'xl:tw-col-start-8 xl:tw-row-start-1': vars.isReversedBool,
        }"
      >
        <library-video-embed
          :video-url="vars.videoUrl"
          :title="vars.mainTitleText"
          :is-popup="true"
          :cover-photo="vars.photoImage"
        />
      </div>

      <h3
        class="tw-order-first tw-flex tw-flex-col tw-gap-4 xl:tw-order-none xl:tw-col-span-7 xl:tw-self-end"
      >
        {{ vars.titleText }}
      </h3>
      <p
        class="tw-flex tw-flex-col tw-gap-4 xl:tw-col-span-7 xl:tw-self-start"
        v-for="(item, index) in descriptions"
        :key="`content-box-description-${index}`"
      >
        {{ item.description }}
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContentBox',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
