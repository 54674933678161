<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-4 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-gap-x-20"
    >
      <NuxtImg
        v-if="vars.photoImage && !vars.videoUrl"
        :src="vars.photoImage"
        :alt="vars.titleText"
        class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
        :class="{
          'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
        }"
      />
      <div
        v-if="vars.videoUrl"
        class="tw-w-full tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
        :class="{
          'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
        }"
      >
        <library-video-embed
          :video-url="vars.videoUrl"
          :title="vars.titleText"
          :is-popup="true"
          :cover-photo="vars.photoImage"
        />
      </div>
      <h3 class="xl:tw-col-span-7 xl:tw-row-start-1 xl:tw-self-end">
        {{ vars.titleText }}
      </h3>
      <div
        class="tw-flex tw-flex-col xl:tw-col-span-7 xl:tw-row-start-2 xl:tw-self-start"
      >
        <p
          class="xl:tw-whitespace-pre-line"
          v-for="(item, index) in descriptions"
          :key="`why-box-description-${index}`"
        >
          {{ item.description }}
        </p>
        <div
          class="tw-grid tw-gap-x-10 tw-gap-y-2 tw-pt-5 sm:tw-mr-auto sm:tw-grid-cols-2"
        >
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <div
              v-if="featuresColumns1"
              v-for="(feature, index) in featuresColumns1"
              :key="index"
              class="tw-flex tw-items-center tw-gap-3"
            >
              <svg
                class="tw-shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="var(--c-secondary)"
                />
              </svg>
              <h6>
                {{ feature.title }}
              </h6>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <div
              v-if="featuresColumns2"
              v-for="(feature, index) in featuresColumns2"
              :key="index"
              class="tw-flex tw-items-center tw-gap-3"
            >
              <svg
                class="tw-shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="var(--c-secondary)"
                />
              </svg>
              <h6>
                {{ feature.title }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'WhyBox',

  mixins: [ComponentMixin],

  computed: {
    featuresColumns1() {
      return this.groupedVariables.column_1_features;
    },
    featuresColumns2() {
      return this.groupedVariables.column_2_features;
    },
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
