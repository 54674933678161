<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-12 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-gap-20"
    >
      <div class="tw-flex tw-flex-1 tw-flex-col tw-gap-8 xl:tw-col-span-7">
        <h3>{{ vars.titleText }}</h3>
        <div class="tw-flex tw-flex-col tw-gap-5">
          <details
            v-for="(faq, index) in faqs"
            :key="index"
            :open="index === openIndex"
          >
            <summary
              class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-duration-300"
              @click.prevent="openIndex = index"
            >
              <h4 class="tw-flex-1">
                {{ faq.title }}
              </h4>
              <svg
                width="36"
                height="36"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="tw-duration-300"
              >
                <path
                  d="M34 20L24 30L14 20"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </summary>
            <p class="tw-mt-2 tw-line-clamp-4 tw-min-h-24">
              {{ faq.description }}
            </p>
          </details>
        </div>
      </div>
      <NuxtImg
        v-if="vars.photoImage && !vars.videoUrl"
        :src="vars.photoImage"
        :alt="vars.titleText"
        class="tw-h-[404px] tw-w-full tw-self-center tw-rounded-xl tw-object-contain xl:tw-col-span-5"
        :class="{
          'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
        }"
      />

      <library-video-embed
        v-if="vars.videoUrl"
        :video-url="vars.videoUrl"
        :cover-photo="vars.photoImage"
        :title="vars.titleText"
        :is-popup="true"
        class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
        :class="{
          'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
        }"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FAQ',

  mixins: [ComponentMixin],

  data() {
    return {
      openIndex: 0,
    };
  },

  computed: {
    faqs() {
      return this.groupedVariables.faqs;
    },
  },
});
</script>

<style scoped>
details[open] > summary svg path,
details > summary:hover svg path {
  transition: all 0.3s;
  stroke: var(--c-secondary);
}

details[open] > summary svg {
  transform: rotate(180deg);
}

details[open] > summary,
details > summary:hover {
  color: var(--c-secondary);
}

details {
  overflow: hidden;
}

details[open] p {
  animation: animateUp 0.5s linear forwards;
}

@keyframes animateUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
