<template>
  <section class="tw-h-[65vh]">
    <div class="tw-absolute tw-inset-0">
      <NuxtImg
        :src="vars.backgroundImage"
        alt="Background Image"
        class="tw-h-full tw-w-full tw-object-cover tw-object-top"
      />
    </div>
    <div
      class="tw-absolute tw-inset-0 tw-flex tw-items-end tw-justify-center tw-bg-black tw-bg-opacity-20 lg:tw-justify-start"
    >
      <h1 class="tw-mb-28 tw-text-white lg:tw-mb-16 lg:tw-ml-28">
        {{ vars.titleText }}
      </h1>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'IntroSection',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
