<template>
  <section
    class="n-section-secondary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <h3>{{ vars.titleText }}</h3>

    <!-- tabs -->
    <div
      class="scrollbar-hide tw-my-4 tw-flex tw-w-96 tw-snap-x tw-snap-mandatory tw-snap-start tw-items-center tw-justify-center tw-overflow-x-auto md:tw-mb-14 md:tw-w-full md:tw-gap-5 md:tw-overflow-x-hidden"
    >
      <button
        v-for="(branch, index) in branches"
        :key="index"
        class="select-button tw-mx-2 !tw-w-[unset]"
        :class="
          selectedBranch === index ? 'select-button active' : 'select-button'
        "
        @click="selectedBranch = branch.id"
      >
        {{ branch.name }}
      </button>
    </div>

    <div class="tw-w-screen">
      <neuron-google-map-v2
        v-if="mapToDisplay && mapToDisplay.length"
        v-model:markers="mapToDisplay"
        :center="mapToDisplay[0]"
        :markerImg="mapToDisplay[0].marker"
      ></neuron-google-map-v2>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import NeuronGoogleMapV2 from '~/components/common/NeuronGoogleMapV2.vue';

export default defineNuxtComponent({
  name: 'BranchesLocation',

  mixins: [ComponentMixin],

  components: { NeuronGoogleMapV2 },

  data() {
    return {
      selectedBranch: 0,
    };
  },

  computed: {
    branches() {
      return this.groupedVariables.branches;
    },

    maps() {
      return this.groupedVariables.locations;
    },

    mapToDisplay() {
      return this.maps.filter((map) => map.branch_id === this.selectedBranch);
    },
  },
});
</script>
