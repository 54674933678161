<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`dynamicComponent-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
// layouts
import FooterOne from '~/components/theme4/base/footer/FooterOne.vue';

// pages components
import TextBanner from '~/components/theme4/components/TextBanner.vue';
import HeroSection from '~/components/theme4/components/HeroSection.vue';
import CardSection from '~/components/theme4/components/CardSection.vue';
import IntroSection from '~/components/theme4/components/IntroSection.vue';
import Testimonials from '~/components/theme4/components/Testimonials.vue';
import TakeAction from '~/components/theme4/components/TakeAction.vue';
import WhySection from '~/components/theme4/components/WhySection.vue';
import InfoBox from '~/components/theme4/components/InfoBox.vue';
import OurValuations from '~/components/theme4/components/OurValuations.vue';
import OurLocations from '~/components/theme4/components/OurLocations.vue';
import BlogDetail from '~/components/theme4/components/BlogDetail.vue';
import WhyBox from '~/components/theme4/components/WhyBox.vue';
import ContentBox from '~/components/theme4/components/ContentBox.vue';
import ContentSection from '~/components/theme4/components/ContentSection.vue';
import OurTeam from '~/components/theme4/components/OurTeam.vue';
import FAQ from '~/components/theme4/components/FAQ.vue';
import BranchesLocation from '~/components/theme4/components/BranchesLocation.vue';
import ContactFormAndImage from '~/components/theme4/components/ContactFormAndImage.vue';
import BranchesContact from '~/components/theme4/components/BranchesContact.vue';
import FeeCalculator from '~/components/theme4/components/FeeCalculator.vue';
import FineAndCountry from '~/components/theme4/components/FineAndCountry.vue';
import VideoTestimonials from '~/components/theme4/components/VideoTestimonials.vue';
import OurGuide from '~/components/theme4/components/OurGuide.vue';

// library components
import FormContainer from '~/components/library/FormContainer.vue';
import Properties from '~/components/library/Properties.vue';
import Register from '~/components/common/register/Register.vue';

// blogs components
import {
  Theme4BlogsPage,
  Theme4BlogsBox,
  Theme4PropertiesLatestProperties,
  LibraryPropertiesOnTheFlySearchBox,
  Theme4PropertiesPropertyDetail,
} from '#components';

// common
import RegisterEntry from '~/components/common/register/RegisterEntry.vue';
import PropertiesEntry from '~/components/common/properties/PropertiesEntry.vue';
import Separator from '~/components/library/Separator.vue';
import RedirectTo from '~/components/common/RedirectTo.vue';
import NotFoundPage from '~/components/theme4/components/NotFoundPage.vue';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },
  },

  components: {
    FormContainer,
    Properties,
    Register,
    RegisterEntry,
    PropertiesEntry,
    FooterOne,
    Separator,
    RedirectTo,
    TextBanner,
    HeroSection,
    BlogsBox: Theme4BlogsBox,
    CardSection,
    LatestProperties: Theme4PropertiesLatestProperties,
    IntroSection,
    Testimonials,
    TakeAction,
    WhySection,
    NotFoundPage,
    OnTheFlyPropertySearchBox: LibraryPropertiesOnTheFlySearchBox,
    InfoBox,
    OurValuations,
    OurLocations,
    PropertyDetail: Theme4PropertiesPropertyDetail,
    BlogsPage: Theme4BlogsPage,
    BlogDetail,
    WhyBox,
    ContentBox,
    ContentSection,
    OurTeam,
    FAQ,
    BranchesLocation,
    ContactFormAndImage,
    BranchesContact,
    FeeCalculator,
    FineAndCountry,
    VideoTestimonials,
    OurGuide,
  },
});
</script>
