<template>
  <section class="tw-h-[80vh]">
    <NuxtImg
      :src="vars.backgroundImage"
      class="tw-absolute tw-h-full tw-w-full tw-object-cover"
    />

    <div
      class="tw-relative tw-m-3 tw-flex tw-max-w-3xl tw-flex-col tw-justify-center tw-gap-5 tw-rounded-lg tw-px-4 tw-py-9 tw-text-start sm:tw-px-8 md:tw-pr-24 xl:tw-ml-10 xl:tw-mr-[40%]"
      :style="`background-color: var(--c-${vars.backgroundColor})`"
    >
      <h3>{{ vars.titleText }}</h3>
      <p>
        {{ vars.descriptionText }}
      </p>
      <div class="tw-mt-2 tw-flex tw-flex-row tw-gap-3">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`card-btn-${index}`"
          :class="`n-${button.type}`"
          :href="button.link"
          :external="true"
        >
          {{ button.label }}
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CardSection',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
